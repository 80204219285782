.custom_divider {
  background-color: #f2f4f7;
  width: 100%;
  height: 2px;
  border-radius: 50px;
}
.divider_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
  padding: 5px 10px;
}
