.offcanvas-header {
    .btn-close {
        position: absolute;
        top: 21px;
        left: 5px;
    }
}

.offcanvas-title {
    p {
        position: absolute;
        top: 15px;
        left: 46px;
    }
}

.offcanvas-body {
    margin-top: 45px;
}