table.datatable {
  width: 100%;
  display: table;
  overflow-x: scroll;
  white-space: nowrap;
  // background-color: #f6fbff;
  border-collapse: collapse;

  thead,
  tfoot {
    width: 100%;
    border-bottom: 1px solid #f2f4f7;
    tr td {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #7189ac;
      padding: 12px 10px;
      user-select: none;
      div {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  tbody {
    position: relative;
    width: 100%;
    min-height: 200px;
    tr {
      border-bottom: 1px solid #f2f3f7;
      &:hover {
        background-color: #00000006;
        border-radius: 100px;
        overflow: hidden;
      }
      td {
        height: min-content;
        color: #445774;
        font-weight: 400;
        font-size: 14px;
        padding: 12px 10px;

        div {
          color: #445774;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}

.table-loader {
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.608);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: progress;
}

.datatable_container {
  width: 100%;
  // border: 1px solid rgba(167, 167, 167, 0.25);
  // border-radius: 20px;
  overflow-x: auto;
  padding: 0 20px;
  position: relative;
}
.hide-table-scroll {
  // width: 100%;
  overflow-x: hidden;
  // padding: 0 20px;
  // position: relative;
}

.column-head {
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 3px 1px;
  transition: 0.1s ease-in-out;
  transition-property: background, transform;
  transform: translateY(0px);
  &:hover {
    // border: 1px solid #b8b8b8c7;
    // background: #eeeded;
    border-radius: 7px;
    padding: 3px 1px;
    cursor: pointer;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    // height: 20px;
    width: 100%;
    background: #000;
    transform: translate(-50%, -50%);
  }
  &:active {
    // border: 1px solid #b8b8b8c7;
    // background: #f5f5f5;
    border-radius: 7px;
    padding: 3px 1px;
    cursor: pointer;
    transform: translateY(3px);
  }
}
