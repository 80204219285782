@import 'Assets//Style/variables';

.select-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .select-container-label {
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    color: #445774;
  }

  .select-container-important {
    color: $red-color !important;
    display: inline-block !important;
  }

  .select-container-info {
    color: $subtitle;
    font-style: italic;
    font-size: 10px;
    margin-left: 2px;
  }

  .select {
    width: 100%;
    height: 34px;
    outline: none;
    border-radius: 8px;
    padding-left: 15px;
    border: 2px solid #dfe4ec;
    cursor: pointer;

    &:focus {
      border: 2px solid #b2b4b7;
    }
  }

  .select-container-errorMessage {
    height: 20px;
    width: fit-content;
    font-size: 12px;
    color: red;
    padding-top: 5px;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}