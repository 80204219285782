@import '../../Assets/Style/variables';
.pagination-section {
  * {
    user-select: none;
  }
  .page-limit {
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0px 0px 26px rgba(178, 191, 210, 0.344);
    background-color: #ffffff;
    padding: 8px 16px;
    margin-left: 12px;
    color: $heading-color;
    span {
      margin-left: 8px;
      color: $input-color;
    }
  }
  .btn {
    width: 28px;
    height: 28px;
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    @include flex(center, center, null);
    background-color: $white;
    &.active-btn {
      border-color: $blue-500;
      background-color: $blue-500;
      color: $white;
    }
  }
  @media (max-width: 576px) {
    .text-muted {
      font-size: 13px;
    }
    .page-limit {
      margin-left: 0;
      padding: 5px 8px;
    }
  }
}
